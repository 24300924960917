import React from 'react'

const Category = () => {
  return (
    <section className='py-5'>
        menu de categorias
    </section>
  )
}

export default Category;