import React from "react";
import "../styles/shop.css";
import Logo from "../assets/img/shop/logo.png";
import Hut from "../assets/img/shop/hut.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faHeart,
  faUser,
  faMagnifyingGlass,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import Timer from "./timer";

export default function ShopHeader() {
  const [show, setShow] = React.useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 6000000); 

  return (
    <header className="shopHeader position-relative">
      <div
        className="ah_banner overflow-hidden"
        style={{ backgroundColor: "#235789" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-2 col-lg-1 border-end">
              <div className="img_wrapper text-center d-flex align-items-center">
                <img src={Logo} alt="" className="img-fluid" style={{ minWidth: '60px' }} />
              </div>
            </div>
            <div className="col-5 col-lg-4">
              <div className="az_content">
                <h4 className="text-white fw-bold fontSize_1 ">
                  Regalos para papá
                  <span style={{ color: "#FFE101" }}>&nbsp;80% dsct</span>
                </h4>
                <Timer
                  text="TERMINA EN"
                  bg="bg-danger"
                  color="text-white"
                  expiryTimestamp={time}
                />
              </div>
            </div>
            <div className="col-5 col-lg-3">
              <div className="az_content before">
                <h4 className="text-white fw-bold fontSize_1 text-center">
                  Tu primera compra es <br />
                  <span style={{ color: "#FFE101" }}>&nbsp;GRATIS</span>
                </h4>
                <h4 className="text-white fw-bold fontSize_1 text-center d-lg-none">
                  Envios a todo el <br />
                  <span style={{ color: "#E71D36" }}>&nbsp;PERU</span>
                </h4>
              </div>
            </div>
            <div className="col-md-3 d-none d-lg-block">
              <div className="az_content">
                <h4 className="text-white fw-bold fontSize_1 text-center ">
                  Envios a todo el <br />
                  <span style={{ color: "#E71D36" }}>&nbsp;PERU</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="menu_toggle space_top d-flex justify-content-between align-items-center  px-2  d-lg-none">
        <div className="img_wrapper mt-3">
          <div className="img_wrapper d-flex" style={{ maxWidth: "50px" }}>
            <img src={Hut} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="toggle_btn mt-3">
          <button className="btnOpen" onClick={() => setShow(true)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
      <div className={show ? "show ah_menu space_top" : "ah_menu space_top"}>
        <div className="container">
          <div className="row">
            <div className="col-12 d-block d-lg-none">
              <div className="toggle_btn text-end">
                <button className="btnClose border-0 bg-transparent fs-5" onClick={() => setShow(false)}>
                  <FontAwesomeIcon icon={faTimes} className="text-white" />
                </button>
              </div>
            </div>
            <div className="col-12 d-flex mt-5 mt-lg-0 flex-column flex-lg-row justify-content-lg-between">
              <div className="az_menu_left d-flex align-items-lg-center flex-column flex-lg-row">
                <div
                  className="img_wrapper d-flex d-lg-block d-none"
                  style={{ maxWidth: "50px" }}
                >
                  <img src={Hut} className="img-fluid" alt="" />
                </div>
                <Link
                  to="/"
                  className="text-dark text-decoration-none bg_greenv1 mt-lg-0 mt-3  ms-lg-2 me-lg-4 fw-bold px-lg-3 py-0 "
                  style={{ width: "fit-content", borderRadius: "20px" }}
                >
                  Abre tu tienda
                </Link>
                <Link
                  to="/"
                  className="text-dark text-decoration-none bg_greenv1 mt-lg-0 mt-3  me-lg-4 fw-bold px-lg-3 py-0 "
                  style={{ width: "fit-content", borderRadius: "20px" }}
                >
                  Directorio
                </Link>
              </div>
              <div className="az_menu_right d-flex align-items-lg-center flex-column flex-lg-row">
                <Link
                  to="/"
                  className="text-decoration-none text_darkv1 mt-lg-0 mt-3 fw-bold fw-lg-light  d-flex align-items-center"
                  style={{ width: "fit-content" }}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    className="text_darkv1 pe-1"
                  />
                  Favoritos
                </Link>
                <Link
                  to="/"
                  className="text-dark text-decoration-none bg_greenv1 mt-lg-0 mt-3  mx-lg-2 fw-bold px-lg-3 py-0 "
                  style={{
                    width: "fit-content",
                    borderRadius: "20px",
                  }}
                >
                  Registrarse
                </Link>
                <div className="d-flex mt-lg-0 mt-3">
                  <FontAwesomeIcon icon={faUser} className="text_darkv1 mt-1" />
                  <Link
                    to="/"
                    className="text-dark text-decoration-none bg_greenv1  mx-2 fw-bold px-lg-3 py-0  "
                    style={{
                      width: "fit-content",
                      borderRadius: "20px",
                    }}
                  >
                    Ingresar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ah_dropdowns pb-2">
        <div className="container">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="d-flex justify-content-md-center justify-content-start flex-wrap">
                <div className="dropdown mx-md-3 me-3 my-2">
                  <button
                    className="btn dropdown-toggle py-1 text_info border_info px-3"
                    type="button"
                    id="dropbtn1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Departamento
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropbtn1">
                    <li>
                      <Link className="dropdown-item" to="/">
                        Action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Another action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Something else here
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="dropdown mx-md-3 me-3 my-2">
                  <button
                    className="btn dropdown-toggle py-1 text_info border_info px-3"
                    type="button"
                    id="dropbtn2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Provincia
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropbtn2">
                    <li>
                      <Link className="dropdown-item" to="/">
                        Action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Another action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Something else here
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="dropdown mx-md-3 my-2">
                  <button
                    className="btn dropdown-toggle py-1 text_info border_info px-3"
                    type="button"
                    id="dropbtn3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Distrito
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropbtn3">
                    <li>
                      <Link className="dropdown-item" to="/">
                        Action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Another action
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Something else here
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form>
                <div
                  className="d-flex justify-content-center my-3 mx-auto"
                  style={{ maxWidth: "500px" }}
                >
                  <div className="dropdown" style={{ marginRight: "-8px" }}>
                    <button
                      className="btn dropdown-toggle py-2 px-2 text-white shadow-none bg_info border_info"
                      type="button"
                      id="dropbtn4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Todos
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropbtn4">
                      <li>
                        <Link className="dropdown-item" to="/">
                          Action
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/">
                          Another action
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/">
                          Something else here
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control shadow-none"
                    placeholder="Escribe lo que deseas buscar"
                    style={{ zIndex: "1", borderRadius: "0" }}
                  />
                  <div
                    className="bg_info d-flex align-items-center position-relative"
                    style={{ borderRadius: "12px", marginLeft: "-8px" }}
                  >
                    <input
                      type="button"
                      name=""
                      id=""
                      value=""
                      className="form-control shadow-none border-0 bg_info text-white mx-3"
                      style={{
                        maxWidth: "65px",
                        borderRadius: " 12px",
                        // zIndex: '1'
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="text-white"
                      style={{
                        fontSize: "18px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
