import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Message from '../Message';
import { listOrders } from '../../action/orderActions';

const OrderList = ({ history }) => {

    const dispatch = useDispatch();

    const orderList = useSelector(state => state.orderList);
    const { loading, error,  orders } = orderList;

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin

    useEffect(() => {
        dispatch(listOrders());

    }, [dispatch]);

    const deleteHandler = () => {

    }


  return (
    <div className='row'> 
        <div className='card-header'>
                <i className="fas fa-table me-1"></i>
                lista de Ordenes
        </div>
        <div className='card-body'>
            {loading 
                ? (<Loader />)
                : error
                    ? (<Message>{error}</Message>)
                    : (
                        <table className='table table-striped table-responsive table-sm'>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">usuario</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Pago</th>
                                    <th scope="col">delivery</th>
                                    
                                    <th>Detalle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.user && order.user.username}</td>
                                        <td>{order.user && order.user.email}</td>
                                        <td>{order.created_at.substring(0, 10)}</td>
                                        <td>s/ {order.total_price}</td>
                                        <td>{order.is_paid ? (
                                            order.paid_at.substring(0, 10)
                                            ) : (
                                                <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                            )}
                                        </td>
                                        <td>{order.is_delivered ? (
                                            order.delivered_at.substring(0, 10)
                                            ) : (
                                                <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                            )}
                                        </td>
                                        <td>
                                            <Link to={`/order/${order.id}`} title='ver orden' >
                                                <i className="fa-solid fa-circle-info" style={{color: '#00b8ff'}}></i>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
            }

        </div>

    </div>
  )
}

export default OrderList