import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { register } from '../action/userActions';
import FormContainer from '../components/FormContainer';
import '../styles/register.css';

const RegisterScreen = ({ location, history }) => {

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPassword_confirmation] = useState('');
  const [message, setMessage] = useState('');

  const dispatch = useDispatch()

  const redirect = location.search ? location.search.split('=')[1] : '/';

  const userRegister = useSelector(state => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== password_confirmation) {
        setMessage('El password no son iguales')
    } else {
        dispatch(register(email, username, first_name, last_name, password_confirmation,  password));
    }
  }

  return (
    <FormContainer>
      <h3 className='text-danger text-center'>Registro de Usuario</h3>
      {message && <Message>{error}</Message>}
      {error && <Message>{error}</Message>}
      {loading && <Loader />}
      <form className=' form-login my-5 cart-register ' onSubmit={submitHandler}>
          <div className='mb-3' id='email'>
            <label className='form-label'>E-mail:</label>
            <input type="email" className='form-control'
              autoComplete='email'
              placeholder='ejemplo@gmail.com'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='mb-3' id='username'>
            <label className='form-label'>Usuario:</label>
            <input type="text" className='form-control'
              autoComplete='username'
              placeholder='username'
              value={username}
              required
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className='mb-3' id='first_name'>
            <label className='form-label'>Primer Apellido:</label>
            <input type="text" className='form-control'
              autoComplete='username'
              placeholder='Perez'
              value={first_name}
              required
              onChange={(e) => setFirst_name(e.target.value)}
            />
          </div>
          <div className='mb-3' id='last_name'>
            <label className='form-label'>Segundo Apellido:</label>
            <input type="text" className='form-control'
              autoComplete='username'
              placeholder='lopez'
              value={last_name}
              required
              onChange={(e) => setLast_name(e.target.value)}
            />
          </div>
          <div className='mb-3' id='password'>
            <label className='form-label'>Password:</label>
            <input type="password" className='form-control'
              placeholder='password'
              autoComplete="current-password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='mb-3' id='password_confirmation'>
            <label className='form-label'>Password:</label>
            <input type="password" className='form-control'
              placeholder='confirm password'
              autoComplete="current-password"
              value={password_confirmation}
              required
              onChange={(e) => setPassword_confirmation(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" type="submit">Registro</button>
          </div>
      </form>
      
      <div className='row py-3'>
        <div className='col'>
          si ya tienes cuenta  ? 
          <Link to={redirect ? `/login?redirect=${redirect}` : `/login`}>
            login
          </Link>
        </div>
      </div>
        
    </FormContainer>
  )
}

export default RegisterScreen