import React from 'react';

const Loader = () => {
  return (
    <div className="spinner-border text-primay"
         role="status" 
         style={{ 
             height: '100px',
             width: '100px',
             margin: 'auto',
             display: 'block'
        }}
    >
        <span className="visually-hidden">Loading...</span>
    </div>
  )
}

export default Loader