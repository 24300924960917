import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { saveShippingAddress } from '../action/cartAction';
import '../styles/shipping.css'

const ShippingScreen = ({ history }) => {

    const cart = useSelector(state => state.cart);
    const { shippingAddress } = cart;

    const dispatch = useDispatch()

    const [address, setAddress] = useState(shippingAddress.address);
    const [city, setCity] = useState(shippingAddress.city);
    const [postal_code, setPostal_code] = useState(shippingAddress.postal_code);
    const [departament, setDepartament] = useState(shippingAddress.departament);
    const [country, setCountry] = useState(shippingAddress.country);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(saveShippingAddress({ address, city, postal_code, departament, country }));
        history.push('/payment');
    }

    return (
        <FormContainer>
            <CheckoutSteps step1 step2 />
            <h3 className='text-center text-danger'>Dirección</h3>
            <form className=' form-login my-3 shipping-car' onSubmit={submitHandler}>
                <div className='mb-3' id='address'>
                    <label className='form-label'>Dirección:</label>
                    <input type="text" className='form-control'
                    autoComplete='address'
                    placeholder='av. mexico'
                    value={address ? address : ''}
                    required
                    onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className='mb-3' id='city'>
                    <label className='form-label'>Ciudad:</label>
                    <input type="text" className='form-control'
                    autoComplete='city'
                    placeholder='Lima'
                    value={city ? city : ''}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className='mb-3' id='postal_code'>
                    <label className='form-label'>Codigo Polatal:</label>
                    <input type="text" className='form-control'
                    autoComplete='city'
                    placeholder='030001'
                    value={postal_code ? postal_code : ''}
                    required
                    onChange={(e) => setPostal_code(e.target.value)}
                    />
                </div>
                <div className='mb-3' id='departament'>
                    <label className='form-label'>Departamento:</label>
                    <input type="text" className='form-control'
                    autoComplete='city'
                    placeholder='Lima'
                    value={departament ? departament : ''}
                    required
                    onChange={(e) => setDepartament(e.target.value)}
                    />
                </div>
                <div className='mb-3' id='country'>
                    <label className='form-label'>Pais:</label>
                    <input type="text" className='form-control'
                    autoComplete='city'
                    placeholder='Perú'
                    value={country ? country : ''}
                    required
                    onChange={(e) => setCountry(e.target.value)}
                    />
                </div>
                <div className="d-grid gap-2">
                    <button className="btn btn-primary" type="submit">continua</button>
                </div>
            </form>
        </FormContainer>
  )
}

export default ShippingScreen