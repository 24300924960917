import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { Link } from "react-router-dom";
import { createOrder } from "../action/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";

const PlaceOrderScreen = ({ history }) => {
  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, error, success } = orderCreate;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  cart.itemsPrice = cart.cartItems
    .reduce((acc, item) => acc + item.price * item.quantity, 0)
    .toFixed(2);
  cart.shippingPrice = (cart.itemsPrice > 100 ? 0 : 10).toFixed(2);
  cart.igvPrice = Number(0.18 * cart.itemsPrice).toFixed(2);
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.igvPrice)
  ).toFixed(2);

  if (!cart.paymentMethod) {
    history.push("/payment");
  }

  useEffect(() => {
    if (success) {
      history.push(`/order/${order.id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [success, history, order, dispatch]);

  const placeOrder = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        igvPrice: cart.igvPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
    <div className="my-5">
      <CheckoutSteps step1 step2 step3 step4 />
      <div className="row">
        <div className="col-md-8">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <h2>Dirección</h2>
              <p>
                <strong>Dirección: </strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}{" "}
                {cart.shippingAddress.postal_code},{" "}
                {cart.shippingAddress.departament},{" "}
                {cart.shippingAddress.country}
              </p>
            </div>
            <div className="list-group-item">
              <h2>Metodo Pago</h2>
              <p>
                <strong>Metodo: </strong>
                {cart.paymentMethod}
              </p>
            </div>
            <div className="list-group-item">
              <h2>Productos en Orden</h2>
              <strong>My Orden: </strong>
              {cart.cartItems.length === 0 ? (
                <Message>Tu carro esta vacio </Message>
              ) : (
                <div className="list-group-flush">
                  {cart.cartItems.map((item, index) => (
                    <div className="list-group-item" key={index}>
                      <div className="row">
                        <div className="col-md-1">
                          <img
                            className="img-fluid img-thumbnail"
                            src={item.image}
                            alt={item.name}
                          />
                        </div>
                        <div className="col">
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </div>
                        <div className="col-md-4">
                          {item.quantity} x s/ {item.price} = s/{" "}
                          {(item.quantity * item.price).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h2>Resumen de Orden</h2>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">Productos:</div>
                  <div className="col"> s/ {cart.itemsPrice}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">Compras:</div>
                  <div className="col">s/ {cart.shippingPrice}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">IGV:</div>
                  <div className="col">s/ {cart.igvPrice}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">
                    {" "}
                    <strong>Total:</strong>
                  </div>
                  <div className="col">s/ {cart.totalPrice}</div>
                </div>
              </div>

              <div className="list-group-item">
                {error && <Message>{error}</Message>}
              </div>

              <div className="list-group-item d-grid gap-2 col-12 mx-auto">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrder}
                >
                  Realizar Pedido
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrderScreen;
