import Profile from '../components/admin/Profile';
import Dashboard from '../components/admin/Dashboard';
import UserList from '../components/admin/UserList';
import CategoriesList from '../components/admin/CategoriesList';
import ProductList from '../components/admin/ProductList';
import OrderList from '../components/admin/OrderList';


const routes =  [
  { path: '/admin', exact: true, name: 'Admin' },
  { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/admin/ptofile', exact: true, name: 'Profile', component: Profile },
  { path: '/admin/userslist', exact: true, name: 'UsersList', component: UserList },
  { path: '/admin/categorylist', exact: true, name: 'CategoriesList', component: CategoriesList },
  { path: '/admin/productslist', exact: true, name: 'ProductList', component: ProductList },
  { path: '/admin/orderslist', exact: true, name: 'OrderList', component: OrderList },
]

export default routes;
