import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import { addToCart, removeFromCart } from '../action/cartAction';
import '../styles/cart.css'

const CartScreen = ({ match, location, history }) => {

  const productId = match.params.id;

  const quantity = location.search ? Number(location.search.split('=')[1]) : 1
  
  const dispatch = useDispatch();

  const cart = useSelector(state => state.cart);
  const { cartItems } = cart

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, quantity));
    }
  }, [dispatch, productId, quantity]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  } 

  const checkoutHandler = () => {
    history.push('/login?redirect=shipping')
  }

  return (
    <div className='row my-5'>
        <div className='col-md-8'>
          <h3>Compras en Carrito</h3>
          {cartItems.length ===0 ? (
            <Message >
              Tu Carro esta vacia <Link to='/' >Atrar</Link>
            </Message>
          ): (
            <div className='list-group list-group-flush'>
              {cartItems.map(item => (
                <div className='list-group-item' key={item.product}>
                  <div className='row'>
                    <div className='col-md-2'>
                      <img src={item.image} alt={item.name} className='img-fluid' />
                    </div>
                    <div className='col-md-4'>
                      <Link to={`/product/${item.product}`}>{item.name}</Link>
                      <p>{item.description}</p>
                    </div>
                    <div className='col-md-2 text-danger'>
                      s/ {item.price}
                    </div>
                    <div className='col-md-2'>
                      <form>
                          <select className="form-select mb-2" required 
                              value={item.quantity}
                              onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value)) )}
                          >
                          {
                              [...Array(item.stock).keys()].map((x) =>(
                                  <option  key={x + 1} value={x + 1}>
                                      {x + 1}
                                  </option>
                              ))
                          }                                                             
                          </select>
                      </form>
                    </div>
                    <div className='col-md-1'>
                      <button type='button'
                        className='btn btn-light btn-sm'
                        onClick={() => removeFromCartHandler(item.product)}
                      >
                        <i className='fa-solid fa-trash'></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='col-md-4 my-3'>
          <div className='card'>
            <div className='list-group list-group-flush'>
              <div className='list-group-item'>
                <h3>Subtotal ({cartItems.reduce((acc, item) => acc + item.quantity, 0 )}) Productos </h3>
                <span className='text-danger'>s/ {cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0 ).toFixed(2)}</span>
              </div>
            </div>
            <div className='list-group-item d-grid gap-2 col-12 mx-auto'>
              <button
                type='button'
                className='btn btn-primary btn-block'
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}

              >
                Proceso a Pagos
              </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default CartScreen;