import React, { useEffect, useState } from 'react';
import Rating from '../components/Rating';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { Link,} from 'react-router-dom';
import { listProductsDetails, createProductReview } from '../action/productAction';
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants';

const ProductScreen = ({ match, history }) => {

    const [quantity, setQuantity] = useState(1)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')

    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product } = productDetails;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const productReview = useSelector(state => state.productReview)
    const { loading: loadingProductReview, error: errorProductReview, success: successProductReview } = productReview;

    useEffect(() => {
  
        dispatch(listProductsDetails(match.params.id))
      
    }, [dispatch, match]);

    const addToCartHandler = () => {
        history.push(`/cart/${match.params.id}?quantity=${quantity}`)
    }

    const submitHandlrer = (e) => {
        e.preventDefault()

    }

    return (
        <div>
           <Link to='/' className='btn btn-primary my-5'> <i className="fa-solid fa-circle-caret-left"></i> Atras </Link>
            {loading ?
                <Loader/>
                : error
                ? <Message>{error}</Message>
                    : (
                        <div>
                            <div className='row'>
                                <div className='col-md-4'>
                                        <img src={product.image} alt={product.name} className='img-fluid' />
                                </div>
                                <div className='col-md-5'>
                                        <div className='list-group list-group-flush'>
                                            <div className='list-group-item'>
                                                <h3 className='fw-bold position-relative d-inline-block text-danger'>{product.name}</h3>
                                            </div>
                                            <div className="list-group-item">
                                                <Rating value={product.rating} text={`${product.number_view} reviews`} color={'#d11d44'} />
                                            </div>
                                            <div className='list-group-item'>
                                                Precio: <h3 className='fw-bold text-danger'>s/ {product.price}</h3> 
                                            </div>
                                            <div className='list-group-item'>
                                                <p className='fw-bold text-danger'>Descripcion: </p> <span>{product.description}</span>
                                            </div>
                                        </div>
                                </div>
                                <div className='col-md-3'>
                                        <div className='card'>
                                            <div className='list-group flush'>
                                                <div className='list-group-item'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            Precio:
                                                        </div>
                                                        <div className='col'>
                                                            <strong className='fw-bold text-danger'>s/ {product.price}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='list-group-item'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            Estado:
                                                        </div>
                                                        <div className='col'>
                                                            {product.stock > 0 ? 'En stock' : 'stock 0'}
                                                        </div>
                                                    </div>
                                                </div>
                                                {product.stock > 0 && (
                                                    <div className='list-group-item'>
                                                        <div className='row'>
                                                            <div className='col'>Catidad:</div>
                                                            <div className='col my-1'>
                                                                <form>
                                                                    <select className="form-select mb-3" required 
                                                                        value={quantity} onChange={(e) => setQuantity(e.target.value)}
                                                                    >
                                                                    {
                                                                        [...Array(product.stock).keys()].map((x) =>(
                                                                            <option  key={x + 1} value={x + 1}>
                                                                                {x + 1}
                                                                            </option>
                                                                        ))
                                                                    }                                                             
                                                                    </select>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                                }
                                                <div className='list-group-item d-grid gap-2 col-12 mx-auto'>
                                                    <button type='button' 
                                                        className='btn btn-primary'
                                                        disabled={product.stock===0}
                                                        onClick={addToCartHandler}
                                                    >
                                                        Add to cart
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h4>Vistas</h4>
                                    {product.reviews.length === 0 && <Message>No vistos</Message>}
                                    <div className='list-group list-group-flush'>
                                        {product.reviews.map((review) =>(
                                            <div className='list-group-item' key={review.id}>
                                                <strong>{review.name}</strong>
                                                <Rating value={review.rating} color='#d11d44' />
                                                <p>{review.created_at.substring(0, 10)}</p>
                                                <p>{review.comment}</p>
                                            </div>
                                        ))}
                                        <div className='list-group-item'>
                                            <h4>Tu cometarios</h4>
                                            {userInfo ? (
                                                <h5>....</h5>
                                            ): (
                                                <Message> Puede <Link to='/login' >login</Link>Logearse para ecribir</Message>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }

        </div>
    )
}

export default ProductScreen