import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { logout } from '../action/userActions'
import MasterLayout from '../layouts/admin/MasterLayout';
import SearchBox from './SearchBox';
import '../styles/header.css'
import Logo from '../assets/img/shopping.png'

const Header = () => {

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const dispatch = useDispatch();

    const logoutHandler = () => {
        dispatch(logout());
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white py-3 fixed-top">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img  src={Logo} alt="logo" />
                    <span className='text-uppercase fw-lighter ms-2 text-danger'>Store Online</span>
                </Link>
                <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav me-auto mb-2 mb-md-0">
                    <SearchBox/>
                    
                </ul>
                <ul className='navbar-nav d-flex'>
                    <li className="nav-item">
                        <Link className="nav-link position-relative" to="/cart">
                            <i className="fa-solid fa-cart-shopping"></i>
                            <span className='position-absolute top-0 bg_primary '>0</span>
                        </Link>
                    </li>
                    {userInfo ? (
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="username" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {userInfo.username}
                            </Link>
                            <ul className="dropdown-menu border-0 text-center" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item text-danger" to='/profile'>Perfil</Link></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><Link className="dropdown-item text-danger" to="#" onClick={logoutHandler}>Logout</Link></li>
                            </ul>
                        </li>
                    ): ( 
                        <li className="nav-item">
                            <Link className="nav-link text-danger" to="/login">Login <i className="fa-solid fa-user"></i></Link>
                        </li>
                    )}

                    {userInfo && userInfo.is_admin && (
                        <MasterLayout />
                    )}
                    
                </ul>
            </div>
            
            </div>
        </nav>
    );
}

export default Header;