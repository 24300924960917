import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { getUserDetails, updateUserProfile } from '../action/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import { listMyOrders } from '../action/orderActions'
import { Link } from 'react-router-dom';

const ProfileScreen = ({ history }) => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [message, setMessage] = useState('');
  
    const dispatch = useDispatch()
  
    const userDetails = useSelector(state => state.userDetails);
    const { loading, error, user } = userDetails;

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const userUpdateProfile = useSelector(state => state.userUpdateProfile);
    const { success } = userUpdateProfile;

    const orderListMy = useSelector(state => state.orderListMy);
    const { loading:loadingOrders, error:errorOrders, orders  } = orderListMy;
  
    useEffect(() => {
      if (!userInfo) {
        history.push('/login');
      } else {
          if(!user || !user.username || success) {
              dispatch({ type: USER_UPDATE_PROFILE_RESET });
              dispatch(getUserDetails('profile'));
              dispatch(listMyOrders());
          } else {
              setUsername(user.username);
              setEmail(user.email);
              setFirst_name(user.first_name);
              setLast_name(user.last_name);
          }
      }
    }, [history, userInfo, dispatch, user, success]);
  
    const submitHandler = (e) => {
      e.preventDefault();
      if (password !== password_confirmation) {
          setMessage('El password no son iguales')
      } else {
          dispatch(updateUserProfile({
            'id': user.id,
            'username': user.username,
            'email': user.email,
            'first_name': user.first_name,
            'last_name': user.last_name,
            'password': user.password
          }));
      }
    }

  return (
    <div className='row py-3'>
        <div className='col-md-3'>
        <h1>Mi Perfil</h1>
        {message && <Message>{error}</Message>}
        {error && <Message>{error}</Message>}
        {loading && <Loader />}
        <form className=' form-login my-5' onSubmit={submitHandler}>
            <div className='mb-3' id='email'>
                <label className='form-label'>E-mail:</label>
                <input type="email" className='form-control'
                autoComplete='email'
                placeholder='ejemplo@gmail.com'
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className='mb-3' id='username'>
                <label className='form-label'>Usuario:</label>
                <input type="text" className='form-control'
                autoComplete='username'
                placeholder='username'
                value={username}
                required
                onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div className='mb-3' id='first_name'>
                <label className='form-label'>Primer Apellido:</label>
                <input type="text" className='form-control'
                autoComplete='username'
                placeholder='Perez'
                value={first_name}
                required
                onChange={(e) => setFirst_name(e.target.value)}
                />
            </div>
            <div className='mb-3' id='last_name'>
                <label className='form-label'>Segundo Apellido:</label>
                <input type="text" className='form-control'
                autoComplete='username'
                placeholder='lopez'
                value={last_name}
                required
                onChange={(e) => setLast_name(e.target.value)}
                />
            </div>
            <div className='mb-3' id='password'>
                <label className='form-label'>Password:</label>
                <input type="password" className='form-control'
                placeholder='password'
                autoComplete="current-password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className='mb-3' id='password_confirmation'>
                <label className='form-label'>Password:</label>
                <input type="password" className='form-control'
                placeholder='confirm password'
                autoComplete="current-password"
                value={password_confirmation}
                required
                onChange={(e) => setPassword_confirmation(e.target.value)}
                />
            </div>
            <div className="d-grid gap-2">
                <button className="btn btn-primary" type="submit">Update</button>
            </div>
        </form>
        </div>
        <div className='col-md-9'>
            <h1>Mis Ordenes</h1>
            {loadingOrders ? (
                <Loader />
            ) : errorOrders ? (
                <Message>{errorOrders}</Message>
            ) : (
                <div className=''>
                    <table className='table table-striped responsive table-sm'>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fecha Orden</th>
                                <th scope="col">Total</th>
                                <th scope="col">Fecha Pagos</th>
                                <th scope="col">Deliveri</th>
                                <th>Detalle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map(order => (
                                <tr key={order.id}>
                                    <td>{order.id}</td>
                                    <td>{order.created_at.substring(0, 10)}</td>
                                    <td>s/ {order.total_price}</td>
                                    <td>{order.is_paid ? order.paid_at.substring(0, 10) : (
                                        <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                    )}</td>
                                    <td>{order.is_delivered ? (
                                        <i className='fa-solid fa-circle-check' style={{color: 'green'}}></i>
                                    ) : (
                                        <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                    )}</td>
                                    <td>
                                        <Link className='btn btn-info btn-sm' to={`/order/${order.id}`}>ver</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    </div>
  )
}

export default ProfileScreen;