import React from 'react'

const FormContainer = ({ children }) => {
  return (
    <div className='container my-5'>
        <div className='row justify-content-center'>
            <div className='col-xs-12 col-md-4'>
                { children }
            </div>
        </div>
    </div>
  )
}

export default FormContainer