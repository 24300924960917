import React from "react";
import { useTimer } from "react-timer-hook";
function Timer(props) {
  
  const {expiryTimestamp} = props
  const {
    hours,
    seconds,
    minutes,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called")
  });

  return (
    <div className={props.bg + " " + "timer_wrapper border p-1 my-1"}>
      <h4
        className={props.color + " " + "p-0 m-0 text-center"}
        style={{ fontSize: "clamp(11px, 1.5vw, 18px)" }}
      >
        {props.text}{" "}
        <span>
         {hours}:{minutes}:{seconds}
        </span>
      </h4>
    </div>
  );
}
export default Timer;
