import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../styles/searchBox.css'


const SearchBox = () => {

  const [keyword, setKeyword] = useState('');

  let history = useHistory()

  const submtHandler = (e) => {
    e.preventDefault()
    if (keyword) {
        history.push(`/?keyword=${keyword}&page=1`)
    }else {
        history.push(history.push(history.location.pathname))
    }
  }

  return (
    <form  onSubmit={submtHandler} className='inline p-1'>
        <input type="text" 
            name="q"
            onChange={(e) => setKeyword(e.target.value)}
            className='search-box mr-sm-8 ml-sm-8'
        />
        <button type='submit'
            className='btn'
        >
            buscar
        </button>
    </form>
  )
}

export default SearchBox