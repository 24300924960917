import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { login } from '../action/userActions';
import FormContainer from '../components/FormContainer';
import '../styles/login.css'

const LoginScreen = ({ location, history }) => {

  const [email, setEmail] = useState('');
  const [password, setPasword] = useState('');

  const dispatch = useDispatch()

  const redirect = location.search ? location.search.split('=')[1] : '/';

  const userLogin = useSelector(state => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password))
  }

  return (
    <FormContainer>
      <h3 className='text-center text-danger'>Inisiar Sesion</h3>
      {error && <Message>{error}</Message>}
      {loading && <Loader />}
      <form className=' form-login my-5 cart-login' onSubmit={submitHandler}>
          <div className='mb-3' id='email'>
            <label className='form-label'>E-mail:</label>
            <input type="email" className='form-control'
              autoComplete='email'
              placeholder='ejemplo@gmail.com'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='mb-3' id='password'>
            <label className='form-label'>Password:</label>
            <input type="password" className='form-control'
              placeholder='password'
              autoComplete="current-password"
              value={password}
              required
              onChange={(e) => setPasword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" type="submit">Inisiar sesion</button>
          </div>
      </form>
      <div className='row py-3'>
        <div className='col'>
          Nuevo usuario ? 
          <Link to={redirect ? `/register?redirect=${redirect}` : `/register`}>
            Registrese
          </Link>
        </div>
      </div>
    </FormContainer>
    
  )
}

export default LoginScreen