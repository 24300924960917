import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import {
  getOrderDetails,
  payOrder,
  deliveredOrder,
} from "../action/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVERED_RESET,
} from "../constants/orderConstants";

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const [sdkReady, setSdkReady] = useState(false);

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDelivered = useSelector((state) => state.orderDelivered);
  const { loading: loadingDelivered, success: successDelivered } =
    orderDelivered;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading && !error) {
    order.itemsPrice = order.orderItems
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
  }

  const addPayPalScript = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&components=YOUR_COMPONENTS";
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    if (
      !order ||
      successPay ||
      order.id !== Number(orderId) ||
      successDelivered
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVERED_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.is_paid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [
    order,
    orderId,
    dispatch,
    successPay,
    successDelivered,
    history,
    userInfo,
  ]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };

  const deliveredHandler = () => {
    dispatch(deliveredOrder(order));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message>{error}</Message>
  ) : (
    <div className="my-5">
      <h1>Orden: {order.id} </h1>
      <div className="row">
        <div className="col-md-8">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <h2>Dirección</h2>
              <p>
                <strong>Nombre:</strong> {order.user.username}{" "}
                {order.user.first_name} {order.user.last_name}
              </p>
              <p>
                <strong>Email:</strong> {order.user.email}
              </p>
              <p>
                <strong>Dirección: </strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}{" "}
                {order.shippingAddress.postal_code},{" "}
                {order.shippingAddress.departament},{" "}
                {order.shippingAddress.country}
              </p>
              {order.is_delivered ? (
                <Message className="alert alert-success">
                  Deliveri ok {order.delivered_at}
                </Message>
              ) : (
                <Message className="alert alert-warning">No Deliveri</Message>
              )}
            </div>
            <div className="list-group-item">
              <h2>Metodo Pago</h2>
              <p>
                <strong>Metodo: </strong>
                {order.payment_method}
              </p>
              {order.is_paid ? (
                <Message className="alert alert-success">
                  Pagado {order.paid_at}
                </Message>
              ) : (
                <Message className="alert alert-warning">No Pagado</Message>
              )}
            </div>
            <div className="list-group-item">
              <h2>Productos en Orden</h2>
              <strong>My Orden: </strong>
              {order.orderItems.length === 0 ? (
                <Message>Tu carro esta vacio </Message>
              ) : (
                <div className="list-group-flush">
                  {order.orderItems.map((item, index) => (
                    <div className="list-group-item" key={index}>
                      <div className="row">
                        <div className="col-md-1">
                          <img
                            className="img-fluid img-thumbnail"
                            src={item.image}
                            alt={item.name}
                          />
                        </div>
                        <div className="col">
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </div>
                        <div className="col-md-4">
                          {item.quantity} x s/ {item.price} = s/{" "}
                          {(item.quantity * item.price).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h2>Resumen de Orden</h2>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">Productos:</div>
                  <div className="col"> s/ {order.itemsPrice}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">Compras:</div>
                  <div className="col">s/ {order.shipping_price}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">IGV:</div>
                  <div className="col">s/ {order.tax_price}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col">
                    {" "}
                    <strong>Total:</strong>
                  </div>
                  <div className="col">s/ {order.total_price}</div>
                </div>
              </div>

              {!order.is_paid && (
                <div className="list-group-item">
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.total_price}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </div>
              )}
            </div>
            {loadingDelivered && <Loader />}
            {userInfo &&
              userInfo.is_admin &&
              order.is_paid &&
              !order.is_delivered && (
                <div className="list-group-item d-grid gap-2 col-12 mx-auto">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={deliveredHandler}
                  >
                    Marca El deliveri
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderScreen;
