import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/contentHeader.css'

const ContentHeader = () => {
  return (
    <header id='header' className='content-header vh-100 carousel slide' data-bs-ride="carousel">
        <div className='constainer h-100 d-flex align-items-center carousel-inner'>
            <div className='text-center carousel-item active'>
                <h2 className='text-capitalize text-white '>Tu Collección para ti  </h2>
                <h1 className='text-uppercase py-2 fw-bolds text-white'>Tu nuevo Tienda en Linea para ti</h1>
                <Link to='#' className='btn mt-3 text-uppercase' >nueva Colección</Link>
            </div>
            <div className='text-center carousel-item'>
                <h2 className='text-capitalize text-white '>Precio y oferta para ti  </h2>
                <h1 className='text-uppercase py-2 fw-bolds text-white'>Tu nuevo Tienda en Linea para ti</h1>
                <Link to='#' className='btn mt-3 text-uppercase' >nueva Colección</Link>
            </div>
        </div>
        <button className='carousel-control-prev' type='button' data-bs-target='#header' data-bs-slide='prev'>
            <span className='carousel-control-prev-icon'></span>
        </button>
        <button className='carousel-control-next' type='button' data-bs-target='#header' data-bs-slide='next'>
            <span className='carousel-control-next-icon'></span>
        </button>
    </header>
    
  )
}

export default ContentHeader;