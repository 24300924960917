import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { productListReducers, productDetailsReducers, productReviewCreateReducers } from './reducers/productReducers';
import { cartReducer,  } from './reducers/cartReducers';
import { userLoginReducers, userRegisterReducers, userDetailsReducers, userUpdateProfileReducers, userListReducers, userDeleteReducers } from './reducers/userReducers';
import { orderCreateReducer, orderDetailsReducer, orderUpdateReducer, orderListMyReducer, orderListReducer, orderDeliveredReducer } from './reducers/orderReducers';
import { CategoryListReducers, CategoryDeleteReducers, categoryCreateReducers } from './reducers/CategoryReducers';


const reducer = combineReducers({
    productList: productListReducers,
    productDetails: productDetailsReducers,
    productReview: productReviewCreateReducers,
    cart: cartReducer,
    userLogin: userLoginReducers,
    userRegister: userRegisterReducers,
    userDetails: userDetailsReducers,
    userUpdateProfile: userUpdateProfileReducers,
    userList: userListReducers,
    userDelete: userDeleteReducers,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderUpdateReducer,
    orderDelivered: orderDeliveredReducer,
    orderListMy: orderListMyReducer,
    orderList: orderListReducer,
    categoryList: CategoryListReducers,
    categoryDelete: CategoryDeleteReducers,
    categoryCreate: categoryCreateReducers,

});

const cartItemsFromStorage = localStorage.getItem('cartItems') ? 
    JSON.parse(localStorage.getItem('cartItems')) : []

const userInfoFromStorage = localStorage.getItem('userInfo') ? 
    JSON.parse(localStorage.getItem('userInfo')) : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ? 
    JSON.parse(localStorage.getItem('shippingAddress')) : { }


const initialState= {
    cart: { 
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage
    },
    userLogin: { userInfo: userInfoFromStorage }
};

const middleware = [thunk]


const store = createStore(reducer, initialState, 
    composeWithDevTools(applyMiddleware(...middleware)));

export default store;