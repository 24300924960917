import React from "react";
import "../styles/shop.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
export default function ShopFooter() {
  return (
    <footer>
      <div className="container">
        <div className="row border-top border-2">
          <div className="col-md-4 mt-2">
            <div className="foo_col1 border-end border-2 h-100 py-2">
              <h6 className="mb-2 text-dark fw-bold">Contáctanos</h6>
              <div className="foo_social">
                <Link to="/" className="text-decoration-none">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    className="mx-2"
                    style={{ color: "#337FFF" }}
                  />
                </Link>
                <Link to="/" className="text-decoration-none">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="mx-2"
                    style={{ color: "#F75274" }}
                  />
                </Link>
                <Link to="/" className="text-decoration-none">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="mx-2"
                    style={{ color: "#33CCFF" }}
                  />
                </Link>
                <Link to="/" className="text-decoration-none">
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="mx-2"
                    style={{ color: "#00D95F" }}
                  />
                </Link>
                <Link to="/" className="text-decoration-none">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="mx-2"
                    style={{ color: "#FF0000" }}
                  />
                </Link>
              </div>
              <small className="text-dark mb-0 d-block">Contáctanos</small>
              <a href="tel:(01)940236290" className="text-decoration-none">
                <small className="text-dark mb-0 d-block">
                  Teléfono: (01)940236290
                </small>
              </a>
              <a href="mailto:hola@shop.com" className="text-decoration-none">
                <small className="text-dark mb-0 d-block">
                  Email: hola@shop.com
                </small>
              </a>
              <small className="text-dark mb-0 d-block">
                Comprar por WhatsApp
              </small>
              <small className="text-dark mb-0 d-block">
                Formas de Pago formas-de-pago
              </small>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="foo_col2 border-end border-2 h-100 py-2">
              <h6 className="mb-2 text-dark fw-bold">
                Conoce nuestros servicios
              </h6>
              <small className="text-dark mb-0 d-block">Contáctanos</small>
              <a href="tel:(01)940236290" className="text-decoration-none">
                <small className="text-dark mb-0 d-block">
                  Teléfono: (01)940236290
                </small>
              </a>
              <a href="mailto:hola@shop.com" className="text-decoration-none">
                <small className="text-dark mb-0 d-block">
                  Email: hola@shop.com
                </small>
              </a>
              <small className="text-dark mb-0 d-block">
                Comprar por WhatsApp
              </small>
              <small className="text-dark mb-0 d-block">
                Formas de Pago formas-de-pago
              </small>
            </div>
          </div>
          <div className="col-md-4 mt-2">
            <div className="foo_col2 border-end border-2 h-100 py-2">
              <h6 className="mb-2 text-dark fw-bold">Servicio al cliente</h6>
              <small className="text-dark mb-0 d-block">Centro de ayuda</small>
              <small className="text-dark mb-0 d-block">
                Cambios y devoluciones
              </small>
              <small className="text-dark mb-0 d-block">
                Politicas de privacidad
              </small>
              <small className="text-dark mb-0 d-block">
                Términos legales de campañas
              </small>
              <small className="text-dark mb-0 d-block">
                Términos y Condiciones
              </small>
              <small className="text-dark mb-0 d-block">Vendamos Juntos</small>
            </div>
          </div>
          <div className="col-12">
            <p
              className="text-dark py-2 fw-bold ms-md-5 ps-md-5 mb-0 text-center text-md-start"
              style={{ fontSize: "12px" }}
            >
              Todos los derechos reservados
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
