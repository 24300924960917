import React from 'react'

export default function Counter() {
    const [count , setCount] = React.useState(0);
    const decre = ()=>{
      if(count == 0) {
        return
      }
      else {
        setCount(count - 1);
      }
    }
    const incre = ()=>{
      setCount(count + 1);
    }
  return (
    <div className='counter_wrapper bg_info d-flex px-1 py-1' style={{ maxWidth: '80px', borderRadius: '0px 8px 8px 0px', fontSize: '14px' }}>
        <input type="button" value="-" onClick={()=> decre()} className='text-white border-0  bg-transparent' name="" id="" />
        <input type="text" value={count} readOnly className="text-center text-white p-0  bg-transparent border-0 w-100" />
        <input type="button" value="+" onClick={()=> incre()} className='text-white border-0  bg-transparent' />
    </div>
  )
}
