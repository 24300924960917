import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Message from '../Message';
import { listCategories, deleteCategory, createCategory } from '../../action/categoryActions';
import { CATEGORY_CREATE_RESET } from '../../constants/categoryConstants';

const CategoriesList = ({ history}) => {

    const dispatch = useDispatch()

    const categoryList = useSelector(state => state.categoryList);
    const {error, categories, loading} = categoryList;

    const categoryDelete = useSelector(state => state.categoryDelete);
    const {error:errorDelete, success:successDelete, loading: loadingDelete} = categoryDelete;

    const categoryCreate = useSelector(state => state.categoryCreate);
    const {error:errorCreate, success:successCreate, loading: loadingCreate, category: createdCategory} = categoryCreate;

    useEffect(() => {
        dispatch({ type: CATEGORY_CREATE_RESET });
        

        if(successCreate) {
            history.psh(`/admin/categorylist/${createCategory.id}/edit`)
        } else {
            dispatch(listCategories());
        }

    }, [dispatch, successDelete, createdCategory, history, successCreate]);

    const deleteHandler = (id) => {
        if(window.confirm('Deceas Elinimas categoria')) { 
            dispatch(deleteCategory(id));
        }
    }

    const regitreHandler = () => {
        dispatch(createCategory())
    }

  return (
    <div className='row'>
        <div className="card">
            <div className='card-header'>
                <i className="fas fa-table me-1"></i>
                lista de categorias
            </div>
        
            <div className='card-body'>
                <div>
                    <button className='btn btn-primary btn-sm' onClick={regitreHandler}>
                        <i className="fas fa-plus-circle"></i>  Nuevo
                    </button>
                </div>
                {loadingDelete && <Loader/> }
                {errorDelete && <Message>{errorDelete}</Message>}

                {loadingCreate && <Loader/> }
                {errorCreate && <Message>{errorCreate}</Message>}
                {loading 
                    ? (<Loader/>)
                    : error 
                        ? (<Message>{error}</Message>)
                        : (
                            <table className='table table-striped table-responsive table-sm'>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Descripcioón</th>
                                        <th scope="col">Imagen</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories.map(category => (
                                        <tr  key={category.id}>
                                            <td>{category.id}</td>
                                            <td>{category.name}</td>
                                            <td>{category.description}</td>
                                            <td>{category.image}</td>
                                            <td>{category.is_status ? (
                                                <i className='fa-solid fa-circle-check' style={{color: 'green'}}></i>
                                                ) : (
                                                    <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                                )}
                                            </td>
                                            <td>
                                                <Link to={`/admin/category/${category.id}`} title='Editar categoria' >
                                                        <i className="fas fa-edit" style={{color: 'orange'}}></i>
                                                </Link>
                                                <button type='button' title='Eliminar categoria' onClick={() => deleteHandler(category.id)} >
                                                        <i className="fas fa-trash" style={{color: 'red'}}></i>
                                                </button>
                                            </td>
                                        </tr> 
                                    ))}
                                </tbody>
                            </table>
                        )
                }
            </div>
        </div>
    </div>
  )
}

export default CategoriesList;