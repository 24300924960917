import axios from 'axios';
import { 
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,
 } from '../constants/productConstants';

 export const listProducts = (keyword='') => async (dispatch) => {
    try {
        dispatch({type: PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(`http://127.0.0.1:8000/products${keyword}`);

        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
        });
        
    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
 }

 export const listProductsDetails = (id) => async (dispatch) => {
    try {
        dispatch({type: PRODUCT_DETAILS_REQUEST });

        const { data } = await axios.get(`http://127.0.0.1:8000/products/${id}`);

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data,
        });
        
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
 }

 export const createProductReview = (productId, review) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_REQUEST 
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            'Content-type': 'application/json',
            Authorization : `token ${userInfo.access_token}`
        }

        const { data } = await axios.post(`http://127.0.0.1:8000/products/${productId}/reviews`,
            review,
            config
        );

        dispatch({
            type: PRODUCT_CREATE_REVIEW_SUCCESS,
            payload: data,
        });
        
    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
 }