import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Message from '../Message';
import { listUsers, deleteUser } from '../../action/userActions';

const UserList = ({ history }) => {

    const dispatch = useDispatch();

    const userList = useSelector(state => state.userList)
    const { users, error, loading } = userList;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const userDelete = useSelector(state => state.userDelete)
    const { loading: LoadingDelete, success:successDelete, error: errorDelete } = userDelete;

    useEffect(() => {
        
        dispatch(listUsers());

    }, [dispatch, history, userInfo, successDelete]);

    const deleteHandler = (id) => {
        if(window.confirm('Deseas eliminar el usuario')) {
            dispatch(deleteUser(id));
        }
    }

    return (
        <div className="card">
            <div className='card-header'>
                <i className="fas fa-table me-1"></i>
                lista de usuarios
            </div>
            <div className='card-body'>
                {loading 
                    ? (<Loader />)
                    : error
                        ? (<Message>{error}</Message>)
                        : (
                            <table className='table table-striped table-responsive table-sm'>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Apellidos</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Admin</th>
                                        <th scope="col">Telefono</th>
                                        <th scope="col">Verificado</th>
                                        <th scope="col">Estado</th>
                                        <th>Detalle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.username}</td>
                                            <td>{user.first_name} {user.last_name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.is_client ?  (
                                                <i className='fa-solid fa-circle-check' style={{color: 'green'}}></i>
                                                ) : (
                                                    <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                                )}
                                            </td>
                                            <td>{user.is_admin ? (
                                                <i className='fa-solid fa-circle-check' style={{color: 'green'}}></i>
                                                ) : (
                                                    <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                                )}
                                            </td>
                                            <td>{user.phone}</td>
                                            <td>{user.is_verified ? (
                                                <i className='fa-solid fa-circle-check' style={{color: 'green'}}></i>
                                                ) : (
                                                    <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                                )}
                                            </td>
                                            <td>{user.is_status ? (
                                                <i className='fa-solid fa-circle-check' style={{color: 'green'}}></i>
                                                ) : (
                                                    <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                                )}
                                            </td>
                                            <td>
                                                <Link to={`/admin/user/${user.id}`} title='Editar user' >
                                                    <i className="fas fa-edit" style={{color: 'orange'}}></i>
                                                </Link>
                                                <button type='button' title='Eliminar user' onClick={() => deleteHandler(user.id)} >
                                                    <i className="fas fa-trash" style={{color: 'red'}}></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )
                }
    
            </div>
        </div>
    )
}

export default UserList;