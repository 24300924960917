import React from 'react';
import { Link } from 'react-router-dom';

const Paginate = ({ pages, page, keyword='', is_admin=false }) => {
  return ( pages > 1 && (
    <nav aria-label="Page navigation example">
        {[...Array(pages).keys()].map((x) =>(
            <ul class="pagination justify-content-center" key={x + 1}>
                <Link to={`/?keyword=${keyword}&page=${x + 1}`}   aria-current="page">
                    <span class="page-item active" > </span>
                </Link>
                
                <li class="page-item">
                <Link class="page-link" href="#">Next</Link>
                </li>
            </ul>
        ))}
    </nav>
  )
    
  )
}

export default Paginate