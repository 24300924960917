import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Message from '../Message';
import { listProducts } from '../../action/productAction';

const ProductList = () => {

    const dispatch = useDispatch();

    const productList = useSelector(state => state.productList);
    const { loading, error,  products } = productList;

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin

    useEffect(() => {
        dispatch(listProducts());

    }, [dispatch]);

    const deleteHandler = () => {

    }


  return (
    <div className='row'> 
        <div className='card-header'>
                <i className="fas fa-table me-1"></i>
                lista de usuarios
        </div>
        <div className='card-body'>
            <div>
                <button type='button' className='btn btn-primary btn-sm'><i class="fas fa-plus-circle"></i> Nuevo</button>
            </div>
            {loading 
                ? (<Loader />)
                : error
                    ? (<Message>{error}</Message>)
                    : (
                        <table className='table table-striped table-responsive table-sm'>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Descripcioón</th>
                                    <th scope="col">Precio</th>
                                    <th scope="col">Stock</th>
                                    <th scope="col">Marca</th>
                                    <th scope="col">Sub categoria</th>
                                    <th scope="col">Estado</th>
                                    <th>Detalle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map(product => (
                                    <tr key={product.id}>
                                        <td>{product.id}</td>
                                        <td>{product.name}</td>
                                        <td>{product.description}</td>
                                        <td>s/ {product.price}</td>
                                        <td>{product.stock}</td>
                                        <td>{product.brand}</td>
                                        <td>{product.sub_category}</td>
                                        <td>{product.is_status ? (
                                            <i className='fa-solid fa-circle-check' style={{color: 'green'}}></i>
                                            ) : (
                                                <i className='fa-solid fa-circle-xmark' style={{color: 'red'}}></i>
                                            )}
                                        </td>
                                        <td>
                                            <Link to={`/admin/user/${product.id}`} title='Editar user' >
                                                <i className="fas fa-edit" style={{color: 'orange'}}></i>
                                            </Link>
                                            <button type='button' title='Eliminar user' onClick={() => deleteHandler(product.id)} >
                                                <i className="fas fa-trash" style={{color: 'red'}}></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
            }

        </div>

    </div>
  )
}

export default ProductList;