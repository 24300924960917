import React from 'react'

const Message = ({ children}) => {
  return (
    <div className="alert alert-danger alert-dismissible fade show"  role="alert">
        {children}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  )
}

export default Message