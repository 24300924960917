import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.css'
import Logo from '../assets/img/shopping.png'

const Footer = () => {
    return (
        <footer className="footer-store py-5">
            <div className='container'>
                <div className='row text-white g-4'>
                    <div className='col-md-6 col-lg-3'>
                        <Link className='text-uppercase text-decoration-none brand text-white' to='/'>
                        <img  src={Logo} alt="logo" /> store online
                        </Link>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt repellat neque hic
                             debitis. Sequi at reprehenderit animi molestias, nisi
                             in vel illo repellat magni. Unde aliquid labore modi ad autem.
                        </p>
                    </div>
                    <div className='col-md-6 col-lg-3'>
                        <h5 className='fw-light'>Links</h5>
                        <ul className=''>
                            <li className='my-3'>
                                <Link to='#' className='text-white text-decoration-none '>
                                <i className="fa-solid fa-caret-right"></i> Prductos
                                </Link>
                            </li>
                            
                            <li className='my-3'>
                                <Link to='#' className='text-white text-decoration-none '>
                                    <i className="fa-solid fa-caret-right"></i> contacto
                                </Link>
                            </li>
                            <li className='my-3'>
                                <Link to='#' className='text-white text-decoration-none '>
                                    <i className="fa-solid fa-caret-right"></i> Nosotros
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-6 col-lg-3'>
                        <h5 className='fw-light'>Contactos</h5>
                        <div className='justify-content-start align-items-start my-2'>
                            <span className='my-3'>
                                <i className="fa-solid fa-badge-dollar"></i>
                            </span>
                            <span>
                                Nuestro local esta en AV juan pablo II s/n Abancay Peru
                            </span>
                        </div>
                        <div className='justify-content-start align-items-start my-2'>
                            <span className='my-3'>
                                <i className="fa-solid fa-badge-dollar"></i>
                            </span>
                            <span>
                                store.suppor@gmail.com
                            </span>
                        </div>
                        <div className='justify-content-start align-items-start my-2'>
                            <span className='my-3'>
                                <i className="fa-solid fa-badge-dollar"></i>
                            </span>
                            <span>
                                +51 952899999
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-3'>
                        <h5 className='fw-light'>Tambien estamos</h5>
                        <ul className='list-unstyled'>
                            <li className=''>
                                <Link to='#' className='text-white text-decoration-none '>
                                <i class="fa-brands fa-facebook"></i>
                                </Link>
                            </li>
                            
                            <li className=''>
                                <Link to='#' className='text-white text-decoration-none '>
                                <i class="fa-brands fa-twitter"></i>
                                </Link>
                            </li>
                            <li className=''>
                                <Link to='#' className='text-white text-decoration-none '>
                                <i class="fa-brands fa-instagram"></i> 
                                </Link>
                            </li>
                            <li className=''>
                                <Link to='#' className='text-white text-decoration-none '>
                                <i class="fa-brands fa-tiktok"></i> 
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;