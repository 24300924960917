import axios from "axios";
import {
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,

    CATEGORY_DELETE_REQUEST,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_FAIL,

    CATEGORY_CREATE_REQUEST,
    CATEGORY_CREATE_SUCCESS,
    CATEGORY_CREATE_FAIL,
 } from '../constants/categoryConstants';

export const listCategories = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORY_LIST_REQUEST,
        });

        const { 
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `token ${userInfo.access_token}`
            }
        }

        const { data } = await axios.get(`http://127.0.0.1:8000/categories/`,
            config
        )

        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch ({
            type: CATEGORY_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteCategory = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORY_DELETE_REQUEST,
        });

        const { 
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `token ${userInfo.access_token}`
            }
        }

        const { data } = await axios.delete(`http://127.0.0.1:8000/categories/${id}`,
            config
        )

        dispatch({
            type: CATEGORY_DELETE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch ({
            type: CATEGORY_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createCategory = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CATEGORY_CREATE_REQUEST,
        });

        const { 
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `token ${userInfo.access_token}`
            }
        }

        const { data } = await axios.post(`http://127.0.0.1:8000/categories/`,
            {},
            config
        )

        dispatch({
            type: CATEGORY_CREATE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch ({
            type: CATEGORY_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}