import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { savePaymentMethod } from "../action/cartAction";

const PaymentScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const dispatch = useDispatch();

  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  if (!shippingAddress.address) {
    history.push("/shipping");
  }

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    history.push("/placeorder");
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <h3 className="text-center text-danger">Metodo de Pago</h3>
      <form className=" form-login pay-cart my-5" onSubmit={submitHandler}>
        <div className="col-12">
          <h3>Seleccione Metodo Pago</h3>
          <div className="form-check">
            <label className="form-check-label">Metodo de pago</label>
            <input
              className="form-check-input"
              type="radio"
              id="paypal"
              name="paymentMethod"
              checked
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </div>
        </div>
        <div className="d-grid gap-2 my-5">
          <button className="btn btn-primary" type="submit">
            continua
          </button>
        </div>
      </form>
    </FormContainer>
  );
};

export default PaymentScreen;
