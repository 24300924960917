import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/product.css';
import Rating from './Rating';

const Product = ({ product }) => {
  return (
    <div className='collection-list mt-4 gx-0 gy-3'>
      <div className='col-md-8 p-2 text-center'>
        <div className='collection-img justify-content-center' >
          <Link to={`/product/${product.id}`}>
            <img src={product.image} className="w-100" alt="product.name" />
          </Link>
        </div>
        <div className='text-center'>
          <div className='rating mt-3'>
            <Rating value={product.rating} text={`${product.number_view} reviews`} color={'#d11d44'} />
          </div>
          <p className="text-capitalize my-1">{product.name}</p>
          <span className='fw-bold text-danger'>s/ {product.price}</span>
        </div>
      </div>
    </div>
    
  )
}

export default Product