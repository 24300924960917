import "./App.css";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HomeScreens from "./screens/HomeScreens";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import MasterLayout from "./layouts/admin/MasterLayout";
// ===================
import Shop from "./screens/Shop";

function App() {
  const location = useLocation();
  return (
    <>
      {location.pathname == "/" ? (
        <Route path="/" component={Shop} exact />
      ) : (
        <>
          <Header />
          <main className="py-3">
            <div className="container-fluid">
              <Route
                path="/admin"
                name="Admin"
                render={(props) => <MasterLayout {...props} />}
              />
              <Route path="/Home" component={HomeScreens} exact />
              <Route path="/product/:id" component={ProductScreen} exact />
              <Route path="/cart/:id?" component={CartScreen} exact />
              <Route path="/login" component={LoginScreen} exact />
              <Route path="/register" component={RegisterScreen} exact />
              <Route path="/profile" component={ProfileScreen} exact />
              <Route path="/shipping" component={ShippingScreen} exact />
              <Route path="/payment" component={PaymentScreen} exact />
              <Route path="/placeorder" component={PlaceOrderScreen} exact />
              <Route path="/order/:id" component={OrderScreen} exact />
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
