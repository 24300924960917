import React from 'react';
import { Link } from 'react-router-dom';

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <div className=' mb-4 my-3'>
        <ul className="nav justify-content-center">
            <li className="nav-item">
                {step1 ? (
                    <Link className="btn nav-link active" aria-current="page" to='/login'>Login</Link>
                ) : (
                    <Link className=" nav-link disabled">Login</Link>
                )}
                
            </li>
            <li className="nav-item">
                {step2 ? (
                    <Link className="btn nav-link active" aria-current="page" to="/shipping">Direccion</Link>
                ) : (
                    <Link className="nav-link disabled" aria-current="page" to="#">Direccion</Link>
                )}
                
            </li>
            <li className="nav-item">
                {step3 ? (
                    <Link className="btn nav-link active" aria-current="page" to="/payment">Pagos</Link>
                ) : (
                    <Link className="nav-link disabled" aria-current="page" to="#">Pagos</Link>
                )}
                
            </li>
            <li className="nav-item">
                {step4 ? (
                    <Link className="btn nav-link active" aria-current="page" to="/placeorder">Pedidos</Link>
                ) : (
                    <Link className="nav-link disabled" aria-current="page" to="#">Pedido</Link>
                )}
                
            </li>
            
        </ul>
    </div>
  )
}

export default CheckoutSteps