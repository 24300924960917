import React from "react";
import "../styles/shop.css";

import Timer from "../components/timer";

import Sitem1 from "../assets/img/shop/item-1.png";
import Poster1 from "../assets/img/shop/poster1.png";
import S2item1 from "../assets/img/shop/S2item1.png";
import Whatsapp from "../assets/img/shop/whatsapp.png";
import location from "../assets/img/shop/location.png";
import world from "../assets/img/shop/world.png";
import mobileHand from "../assets/img/shop/mobilehand.png";
import tick from "../assets/img/shop/tick.png";

import SlickSlider from "../components/SlickSlider";
import Counter from "../components/counter";
import ShopHeader from "../components/shopHeader";
import ShopFooter from "../components/shopFooter";

const slider1 = [
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
  {
    hd1: "Maiz de pollo",
    price1: "S/100.00",
    price2: "S/50.00",
    imgUrl: Sitem1,
  },
];

const slider2 = [
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
  {
    hd1: "Tienda de ropa",
    icon1: Whatsapp,
    icon2: location,
    icon3: world,
    number: "940236290",
    locate: "Lima -  Callo",
    imgUrl: S2item1,
  },
];

const Shop = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 6000000); 

  return (
    <>
      <ShopHeader />
      <main className="shop" style={{ backgroundColor: "#F2F2F2" }}>
        <div className="subMenu mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="subMenu_wrapper d-flex flex-wrap">
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 active dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Ofertas
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Alimentos
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Accesorios
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Ferreteria
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Tecnología
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Modas
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Servicios
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link me-md-2 me-1 dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Ropa
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="menu_link dropdown-toggle"
                      type="button"
                      id="subMenu1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Otros
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="subMenu1">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="shop_001">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec_head d-flex flex-wrap">
                  <h4 className="text-dark mb-0 fw-bold">Ofertas del dia</h4>
                  <h4 className="text-dark mb-0 fw-bold ms-sm-5 ps-sm-5 d-flex align-items-center">
                    Finaliza en
                    <Timer
                      time="23:00"
                      bg="bg-warning px-4 py-0 ms-2 text-dark"
                      color="text-dark"
                      expiryTimestamp={time}
                    />
                  </h4>
                </div>
              </div>
              <div className="col-12">
                <div className="slider_wrapper">
                  <SlickSlider>
                    {slider1.map((item) => {
                      return (
                        <div className="item" key={Math.random()}>
                          <div className="shop_cardv1 mx-1 my-2">
                            <div className="img_wrapper">
                              <img src={item.imgUrl} alt="" className="w-100" />
                            </div>
                            <div className="az_content">
                              <h6 className="text-dark fw-bold mb-1">
                                {item.hd1}
                              </h6>
                              <small>
                                Precio{" "}
                                <span className="text-decoration-line-through text_info">
                                  {item.price1}
                                </span>
                                <span className="text-dark fw-bold">
                                  &nbsp;&nbsp;{item.price2}
                                </span>
                              </small>
                              <button
                                className="bg-warning d-flex align-items-center mt-2 border-0 p-0 pe-3"
                                style={{
                                  borderRadius: "10px",
                                  maxWidth: "140px",
                                }}
                              >
                                <Counter />
                                <small className="ms-1 fw-bold">Comprar</small>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </SlickSlider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="shop_002 py-3">
          <div className="container">
            <div className="row border-top border_info border-2">
              <div className="col-md-9">
                <img src={Poster1} alt="" className="img-fluid mt-3" />
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <button
                    className="border-0 py-2 fw-bold px-3 mt-3"
                    style={{ backgroundColor: "#00AB00", borderRadius: "12px" }}
                  >
                    Abre tu tienda
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="shop_003 py-3">
          <div className="container">
            <div className="row border-top border_info border-2">
              <div className="col-12">
                <div className="sec_head">
                  <h4 className="text-dark mb-0 fw-bold">
                    Tiendas que más venden
                  </h4>
                </div>
              </div>
              <div className="col-12">
                <div className="slider_wrapper">
                  <SlickSlider>
                    {slider2.map((item) => {
                      return (
                        <div className="item" key={Math.random()}>
                          <div className="shop_cardv1 mx-1 my-2">
                            <div className="img_wrapper">
                              <img src={item.imgUrl} alt="" className="w-100" />
                            </div>
                            <div className="az_content">
                              <h6 className="text-dark fw-bold mb-1">
                                {item.hd1}
                              </h6>
                              <div className="d-flex align-items-center mb-1">
                                <div
                                  className="img_wrapper me-2"
                                  style={{ maxWidth: "20px" }}
                                >
                                  <img
                                    src={item.icon1}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <small className="fw-bold">{item.number}</small>
                              </div>
                              <div className="d-flex align-items-center mb-1">
                                <div
                                  className="img_wrapper me-2"
                                  style={{ maxWidth: "20px" }}
                                >
                                  <img
                                    src={item.icon2}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <small className="fw-bold">{item.locate}</small>
                              </div>
                              <button
                                className="d-flex align-items-center mt-2 p-0 pe-3 border-0"
                                style={{
                                  borderRadius: "10px",
                                  maxWidth: "140px",
                                  backgroundColor: "#00AB00",
                                }}
                              >
                                <div
                                  className="img_wrapper bg_info px-1 py-1"
                                  style={{
                                    maxWidth: "60px",
                                    borderRadius: "0px 8px 8px 0px",
                                    fontSize: "14px",
                                  }}
                                >
                                  <img
                                    src={item.icon3}
                                    alt=""
                                    className="img-fluid px-2"
                                    style={{ maxWidth: "40px" }}
                                  />
                                </div>
                                <small className="ms-1 fw-bold text-white">
                                  Visitar
                                </small>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </SlickSlider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="shop_004 py-3">
          <div className="container">
            <div className="row border-top border_info border-2">
              <div className="col-12 my-3">
                <div className="sec_head text-center">
                  <h4 className="text-dark mb-1 fw-bold">¿Que ofrecemos?</h4>
                </div>
              </div>
            </div>
            <div className="row align-items-sm-center border-top border_info border-2">
              <div className="col-sm-4 mt-4">
                <div className="img_wrapper text-center">
                  <img src={mobileHand} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="d-flex mb-3">
                  <img
                    src={tick}
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "30px" }}
                  />
                  <p className="mb-0 ms-2 fw-bold">
                    Herramientas para impulsar tus ventas
                  </p>
                </div>
                <div className="d-flex mb-3">
                  <img
                    src={tick}
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "30px" }}
                  />
                  <p className="mb-0 ms-2 fw-bold">gestionamos tus ventas</p>
                </div>
                <div className="d-flex mb-3">
                  <img
                    src={tick}
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "30px" }}
                  />
                  <p className="mb-0 ms-2 fw-bold">Promociamos tus productos</p>
                </div>
                <div className="d-flex mb-3">
                  <img
                    src={tick}
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "30px" }}
                  />
                  <p className="mb-0 ms-2 fw-bold">
                    Te capacitamos con los expertos
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ShopFooter />
    </>
  );
};

export default Shop;
