import { 
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,
    CATEGORY_LIST_RESET,

    CATEGORY_DELETE_REQUEST,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_FAIL,

    CATEGORY_CREATE_REQUEST,
    CATEGORY_CREATE_SUCCESS,
    CATEGORY_CREATE_FAIL,
    CATEGORY_CREATE_RESET
 } from '../constants/categoryConstants';

 export const CategoryListReducers = (state = { categories:[] }, action) => {
    switch(action.type ) {
        case CATEGORY_LIST_REQUEST:
            return { loading: true }
        
        case CATEGORY_LIST_SUCCESS:
            return { loading: false, categories: action.payload }
        
        case CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload }

        case CATEGORY_LIST_RESET:
                return { categories: [] }
        
        default:
            return state;
    }
 }

 export const CategoryDeleteReducers = (state = {}, action) => {
    switch(action.type ) {
        case CATEGORY_DELETE_REQUEST:
            return { loading: true }
        
        case CATEGORY_DELETE_SUCCESS:
            return { loading: false, success: true }
        
        case CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state;
    }
 }

 export const categoryCreateReducers = (state = {}, action) => {
    switch(action.type ) {
        case CATEGORY_CREATE_REQUEST:
            return { loading: true }
        
        case CATEGORY_CREATE_SUCCESS:
            return { loading: false, success: true, category: action.payload }
        
        case CATEGORY_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case CATEGORY_CREATE_RESET:
            return {}
        
        default:
            return state;
    }
 }