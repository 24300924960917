import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Product from '../components/Product';
import Loader from '../components/Loader';
import { listProducts } from '../action/productAction';
import Message from '../components/Message';
import ContentHeader from '../components/ContentHeader';
import Category from '../components/Category';
import Paginate from '../components/Paginate';


const HomeScreens = ({ history }) => {

 const dispatch = useDispatch()
 const productList = useSelector(state => state.productList)
 const { error, loading, products } = productList

 let keyword = history.location.search

  useEffect(() => {
    dispatch(listProducts(keyword))
    
  }, [dispatch, keyword]);

  return (
    <div className='my-5'>
        {loading ? <Loader />
                : error ? <Message>{error}</Message>
                  :
                  <div>
                    <div className='row'>
                      <ContentHeader />
                      <Category />
                        {products.map(product => (
                            <div key={product.id} className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
                                <Product product={product}/>
                            </div>
                        ))}
                    </div>
                    <Paginate />
                </div>
        }
    </div>
  )
}

export default HomeScreens